import React, { useEffect } from "react";

const Form230ro: React.FC = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://formular230.ro/share/56ba791b8";
    script.async = true;
    document.body.appendChild(script);

    // Cleanup script when the component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return <div className="f230ro-formular"></div>;
};

export default Form230ro;
