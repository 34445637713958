import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyDLcQqBjMpaIec51qnQGx0fJPvzxDqgVCQ",
  authDomain: "resurse-scouts-cluj.firebaseapp.com",
  projectId: "resurse-scouts-cluj",
  storageBucket: "resurse-scouts-cluj.appspot.com",
  messagingSenderId: "402144430485",
  appId: "1:402144430485:web:de14809a47e3478b2f8e9c",
  measurementId: "G-3QDF88L6VR",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const db = getFirestore(app);
