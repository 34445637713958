import React, { useEffect, useState } from "react";
import Form230ro from "../components/Form230ro";
import SourceForm230ro from "../components/SourceForm230ro";
import { useNavigate } from "react-router-dom";

const Form230roPage: React.FC = () => {
  const [formSubmitted, setFormSubmitted] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    document.title = "3.5% pentru Cercetași";
  }, []);

  const handleFormSubmit = () => {
    setFormSubmitted(true);
  };

  return (
    <div>
      {!formSubmitted ? (
        <div>
          <SourceForm230ro onSubmit={handleFormSubmit} />
        </div>
      ) : (
        <Form230ro />
      )}
    </div>
  );
};

export default Form230roPage;
