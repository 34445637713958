import React, { useState } from "react";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { collection, addDoc } from "firebase/firestore";
import Button from "@mui/material/Button";
import { db } from "../firebaseConfig";
import TextField from "@mui/material/TextField";
import { Container, Grid, Typography, makeStyles } from "@mui/material";

interface SourceForm230roProps {
  onSubmit: () => void; // Define the type for onSubmit
}

const SourceForm230ro: React.FC<SourceForm230roProps> = ({ onSubmit }) => {
  const [selectedOption, setSelectedOption] = useState("");
  const [recommendationName, setRecommendationName] = useState("");

  const options = ["Facebook", "Website", "Recomandare personala"];

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedOption(event.target.name);
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    // Check if the 'Recomandare personala' is selected and the name is not provided
    if (selectedOption === "Recomandare personala" && !recommendationName) {
      alert("Please provide the name for the recommendation.");
      return;
    }

    const formData = {
      source: selectedOption,
      sourceName:
        selectedOption === "Recomandare personala" ? recommendationName : "",
    };

    try {
      await addDoc(collection(db, "formSubmissionSources"), formData);
      console.log("Data successfully saved to Firestore");
      setSelectedOption("");
      setRecommendationName("");
      onSubmit();
    } catch (error) {
      console.error("Error adding document to Firestore: ", error);
    }
  };

  return (
    <Container
      component="main"
      maxWidth="xs"
      sx={{
        mt: 4,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <img
        src="./images/logoScoutsCluj.png"
        alt="Description of the image"
        style={{
          width: "150px",
          height: "150px",
        }}
      />

      <Typography component="h5" variant="h6">
        De unde ai aflat de campania noastră?
      </Typography>
      <form
        onSubmit={handleSubmit}
        style={{ width: "100%", maxWidth: 400, marginTop: 16 }}
      >
        <FormGroup>
          <Grid container spacing={2}>
            {options.map((option) => (
              <Grid item xs={12} key={option}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={selectedOption === option}
                      onChange={handleCheckboxChange}
                      name={option}
                    />
                  }
                  label={option}
                />
              </Grid>
            ))}
          </Grid>
        </FormGroup>

        {selectedOption === "Recomandare personala" && (
          <TextField
            label="Numele persoanei care a recomandat"
            value={recommendationName}
            onChange={(e) => setRecommendationName(e.target.value)}
            fullWidth
            margin="normal"
            required
          />
        )}

        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          sx={{ mt: 3, mb: 2 }}
          disabled={
            !selectedOption ||
            (selectedOption === "Recomandare personala" && !recommendationName)
          }
        >
          Mai departe
        </Button>
      </form>
    </Container>
  );
};

export default SourceForm230ro;
